import React from "react";
import { NavHashLink as Link } from 'react-router-hash-link';

import "../css/project-grid.css";

import { PhilosophieSvg, EquipeSvg, AtelierSvg } from "../assets/svg/";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Maison = ({ striped }) => (
  <div id="maison" className={`section ${striped}`}>
    <div className="content">
      <h1 className="sectiontitle">
        <span style={{ letterSpacing: "3px" }}>
          <span className="red">C</span>ODDITY <span className="red">?</span>
        </span>
      </h1>
      <div className="block-content">
        <div className="project-grid">

          <div className="project-grid-item center">
            <PhilosophieSvg id="easteregg-gamestarter" />
            <h1>
              <span className="red">P</span>hilosophie
            </h1>
            <p className="center pad-text">
              <span className="red">C</span>oddity est une manufacture numérique engagée éditrice de produits SaaS.
              Nous combinons nos savoir-faire en <b>IA</b> et <b>en logiciel</b> pour produire des solutions industrialisées
              et conformes au RGPD.
            </p>

            <Link to='/#services' smooth duration={500}>
              <button className="btn btn-center bluebtn">
                Nos offres <FontAwesomeIcon icon={faArrowRight} className="iconMargin" />
              </button>
            </Link>
          </div>

          <div className="project-grid-item center">
            <EquipeSvg />
            <h1>
              <span className="red">É</span>quipe
            </h1>
            <p className="center pad-text">
              A l'origine, un <b>noyau de passionnés</b> mettant en oeuvre leurs compétences, leurs expériences et leurs idées
              dans l'aventure, nous sommes aujourd'hui une équipe de <b><span className="clickMe">20</span> artisans</b> taillée pour 
              intervenir auprès de PME/ETI et administrations publiques.
            </p>

            <Link to='/#references'>
              <button className="btn btn-center bluebtn">
                Nos références <FontAwesomeIcon icon={faArrowRight} className="iconMargin" />
              </button>
            </Link>
          </div>

          <div className="project-grid-item center">
            <AtelierSvg />
            <h1>
              L'Atelier
            </h1>
            <p className="center pad-text">
              Nous organisons tous les mois des <span className="red">A</span>teliers.
              Il s'agit d'une journée dédiée à la <b>R&D</b>, au test de nouveaux modèles ou librairies,
              pouvant aboutir à des produits commercialisés ou open source.
            </p>

            <Link to='/#produits'>
              <button className="btn btn-center bluebtn">
                Nos produits <FontAwesomeIcon icon={faArrowRight} className="iconMargin" />
              </button>
            </Link>
            <br /><br />
            <Link to='/atelier'>
              <button className="btn btn-center">
                Nos projets de R&D <FontAwesomeIcon icon={faArrowRight} className="iconMargin" />
              </button>
            </Link>
            
            
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Maison;
