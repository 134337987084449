import React, { useEffect } from "react";
import Home from "./Home.js";
import Maison from "./Maison";
import Savoirfaire from "./Savoirfaire";
import Services from "./Services";
import Produits from "./Produits";
import Temoignages from "./Temoignages"
import { enterSectionPage, quitSectionPage } from "../easteregg/GameStarter";

const Sections = () => {
    
    useEffect(() => {
        // ComponentDidMount
        enterSectionPage();

        // ComponentWillUnmount
        return () => {
            quitSectionPage();
        };
    }, []);
    
    return (
        <div id='ui-view'>
            <Home />
            <Maison striped="dark" />
            <Services />
            <Savoirfaire striped="dark" />
            <Produits />
            <Temoignages striped={"dark"} />
        </div>
    );
}

export default Sections;
