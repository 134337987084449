import React from 'react';
import LogoItem from './logoitem/LogoItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import { ConsultingSvg } from '../assets/svg/';
import PrototypeSvg from '../img/prototype.svg';
import BoxSvg from '../img/box.svg';

import "../css/project-grid.css";

const Services = ({striped}) => (
    <div id="services" className={`section ${striped}`}>
        <div className="content">
            <h1 className="sectiontitle">
                Services
            </h1>
            <div className="block-content">

                <div className="project-grid project-grid-max-3">

                    <div className="project-grid-item center">
                        
                        <LogoItem imgSvg={BoxSvg} />
                        
                        <h1>Applications Studio</h1>
                        <p className="pad-text">
                            <b>Nous créons des applications industrialisées pour vos processus métier</b>.
                            Nous intervenons pour les PME / ETI et le secteur public :
                            sur la base d'un cahier des charges, nous assurons le pilotage des projets au forfait,
                            du cadrage jusqu'à la maintenance.
                        </p>
                    </div>
                    
                    <div className="project-grid-item center">
                        
                        <LogoItem imgSvg={PrototypeSvg} />
                        
                        <h1>Agent Studio</h1>
                        <p className="pad-text">
                            <b>Nous créons des agents IA sur-mesure pour vos besoins métier</b>.
                            Ces agents autonomes sont interconnectés avec votre SI et nous supervisons
                            leur performances et l'absence de biais.
                            Nous assurons la conformité RGPD / AI Act et formons vos équipes
                            pour une adoption éthique et responsable.
                        </p>
                    </div>

                    <div className="project-grid-item center">
                        
                        <ConsultingSvg />
                        
                        <h1>IA Transform</h1>
                        <p className="pad-text">
                            <b>Préparez votre organisation à l'ère de l'IA</b>.
                            Nous auditons et optimisons vos actifs d'entreprise pour maximiser leur potentiel.
                            Notre approche structurée transforme votre documentation,
                            adapte vos processus et forme vos équipes pour une transition réussie vers l'IA.
                        </p>
                    </div>

                </div>

                <p className="pad-text center">
                    <a href="mailto:contact@coddity.com" target="_blank" rel="noopener noreferrer">
                        <button className="btn btn-center sendbtn">
                            En savoir plus <FontAwesomeIcon icon={faEnvelope} className="iconMargin" />
                        </button>
                    </a>
                </p>
            </div>

        </div>
    </div>
);

export default Services;
